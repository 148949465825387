import { initReactI18next } from "react-i18next";
import i18n from "i18next";

i18n.use(initReactI18next).init({
  lng: localStorage.getItem("xb-lang") ?? "en",
  resources: {
    en: {
      translation: {
        Login: "Login",
        done: "done",
        Register: "Register",
        URL_NOT_FOUND: "URL NOT FOUND",
        name: "name",
        email: "email",
        password: "password",
        id: "id",
        opponents: "opponents",
        price: "price",
        time: "time",
        Time: "Time",
        buy: "buy",
        tickets: "buy tickets",
        Next_game_starting_in: "Next game starting in",
        Game: "Game",
        Prize: "Prize",
        You_are_about_to_logout: "Are you sure you want to logout?",
        ok: "ok",
        cancel: "cancel",
        date: "date",
        winner: "winner",
        bought: "bought",
        is: "is",
        the: "the",
        WINNER: "WINNER",
        of: "of",
        Terms: "Terms",
        Conditions: "Conditions",
        None: "None",
        Go: "Go",
        To: "To",
        None_rooms_are_available: "No rooms are available",
        Welcome_to_Bingo_Lobby: "Welcome to Bingo Lobby",
        Enter_the_game: "Enter",
        Ticket_Purchased:"Ticket purchased",
        Game_Started:"Game Started",
        is_the_Winner: "is the WINNER",
        Go_To_Lobby:"Go To Lobby"
      },
    },
    fr: {
      translation: {
        Login: "Connexion",
        done: "fini",
        Register: "Enregistrer",
        URL_NOT_FOUND: "URL introuvable",
        name: "Nom",
        email: "e-mail",
        password: "le mot de passe",
        id: "identifiant",
        opponents: "adversaires",
        price: "le prix",
        time: "temps",
        Time: "Temps",
        buy: "acheter",
        tickets: "acheter des billets",
        Next_game_starting_in: "Prochain match commençant dans",
        Game: "Jeu",
        Prize: "gagnera",
        You_are_about_to_logout: "Êtes-vous sûr de vouloir vous déconnecter?",
        ok: "ok",
        cancel: "annuler",
        date: "date",
        winner: "gagnant",
        bought: "acheté",
        is: "is",
        the: "est",
        WINNER: "GAGNANT",
        of: "de",
        Terms: "termes",
        Conditions: "Les conditions",
        None: "Aucun",
        Go: "Va",
        To: "Pour",
        None_rooms_are_available: "Aucune chambre n'est disponible",
        Welcome_to_Bingo_Lobby: "Bienvenue dans le hall du bingo",
        Enter_the_game: "Entrer",
        Ticket_Purchased:"Billet acheté",
        Game_Started:"Jeu commencé",
        is_the_Winner: "is the WINNER",
        Go_To_Lobby:"Go To Lobby"
      },
    },
    ru: {
      translation: {
        Login: "Авторизоваться",
        done: "Выполнено",
        Register: "регистр",
        URL_NOT_FOUND: "URL-адрес НЕ НАЙДЕН",
        name: "название",
        email: "Эл. адрес",
        password: "пароль",
        id: "я бы",
        opponents: "противники",
        price: "цена",
        time: "время",
        Time: "время",
        buy: "купить",
        tickets: "купить билеты ",
        Next_game_starting_in: "Следующая игра начнется через",
        Game: "Игра",
        Prize: "Приз",
        You_are_about_to_logout: "Вы действительно хотите выйти?",
        ok: "Хорошо",
        cancel: "отменить",
        date: "дата",
        winner: "победитель",
        bought: "купили",
        is: "является",
        the: "в",
        WINNER: "ПОБЕДИТЕЛЬ",
        of: "из",
        Terms: "Условия",
        Conditions: "Условия",
        None: "Никто",
        Go: "Идти",
        To: "К",
        None_rooms_are_available: "Нет свободных номеров",
        Welcome_to_Bingo_Lobby: "Добро пожаловать в Лобби Бинго",
        Enter_the_game: "Входить",
        Ticket_Purchased:"Билет куплен",
        Game_Started:"Игра началась",
        is_the_Winner: "ПОБЕДИТЕЛЬ",
        Go_To_Lobby:"Перейти в лобби"
      },
    },
    he: {
      translation: {
        Login: "התחברות",
        done: "סיום",
        Register: "רישום",
        URL_NOT_FOUND: "קישור לא נמצא",
        name: "שם",
        email: "מייל",
        password: "סיסמה",
        id: "מזהה",
        opponents: "יריבים",
        price: "מחיר",
        time: "זמן",
        Time: "שעה",
        buy: "קניה",
        tickets: "רכישת כרטיסים",
        Next_game_starting_in: "משחק הבא מתחיל בעוד:",
        Game: "משחק",
        Prize: "סכום הזכיה",
        You_are_about_to_logout: "האם אתה בטוח שאתה רוצה להתנתק?",
        ok: "אוקי",
        cancel: "בטל",
        date: "תאריך",
        winner: "זוכה",
        bought: "נרכש",
        is: "הוא",
        the: "א",
        WINNER: "זוכה",
        of: "ב",
        Terms: "תנאים",
        Conditions: "תנאים",
        None: "כלום",
        Go: "לך",
        To: "אל",
        None_rooms_are_available: "לא נמצאו חדרים בהמתנה",
        Welcome_to_Bingo_Lobby: "ברוכים הבאים ללובי",
        Enter_the_game: "כניסה למשחק",
        Ticket_Purchased:"כרטיס נרכש",
        Game_Started:"המשחק התחיל",
        is_the_Winner: "הוא המנצח",
        Go_To_Lobby:"חזרה ללובי"
      },
    },

  },
  keySeparator: false,
  interpolation: { escapeValue: false },
});

export default i18n;
