import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
//import i18n from "i18next";
import Footer from "components/Footer";
import Header from "components/Header";
import Timer from "components/Timer";
import TimeNow from "components/TimeNow";

export default function Lobby({ t }) {
  let navigate = useNavigate();

  const [state, setState] = useState({
    rooms: [],
    announcementText: t("Welcome_to_Bingo_Lobby"),
  });

  const fetchRooms = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_HOST}/rooms?all=true`,
        {
          headers: {
            "x-access-bingo-token":
              JSON.parse(sessionStorage.getItem("x-bingo-token")) ?? null,
          },
        }
      );

//       const date = new Date();
//       const hoursAndMinutes = date.getHours() + '' + date.getMinutes();

// const nowTime = hoursAndMinutes;
// const targetTime1 = data.data[0].start_time.split(" ");
// const targetTime = targetTime1[1].replace(":","");
// const gid = data.data[0].id;
// const timeToStart = (targetTime - nowTime);
// //console.log(data.data[0].start_time.replace(":",""),hoursAndMinutes);
// if(timeToStart <= 70)
// {
//   //navigate('/game?_id='+gid);
//   //window.location = '/game?_id='+gid
//   setTimeout(() =>
//   {
// navigate('/game?_id='+gid);
//   },4000)
  
  
// }
// console.log(targetTime - nowTime, gid);

      setState({ ...state, rooms: data.data });
    } catch (err) {
      console.error(err);
      swal(err.response.data.message);
      if (err.response.status === 401) {
        navigate("/");
      }
    }
  };

  // const startGame = (room) => {
  //   const currentTime = new Date().toLocaleString("en-US", {
  //     hour12: false,
  //     year: "numeric",
  //     month: "2-digit",
  //     day: "2-digit",
  //     hour: "2-digit",
  //     minute: "2-digit",
  //     second: undefined,
  //   });

  //   if (currentTime >= room.start_time && room.opponents > 1)
  //     navigate(`/game?_id=${room.id}`, {
  //       state: { time: room.start_time.split(" ")[1] },
  //     });
  //   else swal("Room has been closed or hasn't started yet.");
  // };

  const startGame = (room) => {
    navigate(`/game?_id=${room.id}`, {
      state: { time: room.start_time },
    });
  };

  const purchaseTicket = (room) => {
    const twoMinsBeforeRoomStart = new Date(
      new Date(room.start_time) - 2 * 60000
    ).toLocaleString("en-US", {
      hour12: false,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: undefined,
    });

    const currentTime = new Date().toLocaleString("en-US", {
      hour12: false,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: undefined,
    });

    if (currentTime >= twoMinsBeforeRoomStart)
      swal("Ticket counter closed for this room.");
    else
      navigate(`/tickets?_id=${room.id}`, {
        state: {
          nextGame: state.rooms.length ? state.rooms[0].start_time : null,
        },
      });
  };

  useEffect(() => {
    setInterval(() =>
    {
      fetchRooms();
    },10000)
    
    return () => {};
  }, []);

  useEffect(() => {

      fetchRooms();
   
    
    return () => {};
  }, []);


  return (
    <div className="ticket-table px-3">
      <div className="table-content pt-1">
        <Header />
        <TimeNow t={t} />
        {state.rooms.length ? (
          <div className="table mt-3">
            <table className="table table-sm">
              <thead className="text-secondary-light">
                <tr>
                  
                  <th>{t("opponents")}</th>
                  <th>{t("price")}</th>
                  <th>{t("time")}</th>
                  <th>{t("buy")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="text-light">
                {state.rooms.map((room, index) => (
                  <tr key={room.id}>
                    
                    <td>{room.opponents}</td>
                    <td>${room.ticket_price}</td>
                    <td className="text-success">
                      {room.start_time.split(" ")[1]}
                    </td>
                    <td>
                      <button
                        style={{ border: "none" }}
                        onClick={() => purchaseTicket(room)}
                        className="text-white btn btn-sm btn-ticket"
                      >
                        {t("tickets")}
                      </button>
                    </td>
                    <td className="text-warning">
                      <button
                        style={{ border: "none", background: "#438745", borderRadius: "5px", padding: "2px" }}
                        onClick={() => startGame(room)}
                        className="text-warning"
                      >
                       {t("Enter_the_game")} 
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <h5 className="text-white text-center">
            {t("None_rooms_are_available")}
          </h5>
        )}

        <Timer
          time={state.rooms.length ? state.rooms[0].start_time : null}
          t={t}
        />

        <div className="annoucments text-center text-light border-warning py-2">
          <h5>{state.announcementText}</h5>
          Hello
        </div>

        <Footer time={state.rooms.length ? state.rooms[0].start_time : null} />
      </div>
    </div>
  );
}
