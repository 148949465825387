import Countdown, { zeroPad, calcTimeDelta, formatTimeDelta } from 'react-countdown';

const Timer = ({ time, t, color = "white", setTimerOff = () => {} }) => {
  // Renderer callback with condition
  const renderer = ({ formatted: { hours, minutes, seconds }, completed }) => {
    if (completed) {
      setTimerOff();
      // Render a completed state
      return (
        <h3 className="text-secondary-light" style={{ color: color }}>
          {t("Game_Started")}!
        </h3>
      );
    } else {
      // Render a countdown
      return (
        <>
          <h3 className="text-secondary-light" style={{ color: color }}>
            {t("Next_game_starting_in")}
          </h3>
          <span>
          {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </>
      );
    }
  };

  return (
    <div
      className="start-game-time text-center font-w-s mt-3 text-light"
      style={{ color: color }}
    >
      {time && (
        <Countdown
          date={Date.now() + (new Date(time) - new Date() + 10000)}
          // date={Date.now() + 1799999}
          renderer={renderer}
        />
      )}
    </div>
  );
};

export default Timer;
